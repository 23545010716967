<template>
    <div>
        <dashboard
            v-if="uppy"
            :uppy="uppy"
            :props="{
                showLinkToFileUploadResult: false,
                showProgressDetails: true,
                hideCancelButton: false,
                proudlyDisplayPoweredByUppy: false,
                note: 'Folders with many files might take some time to appear. To upload a folder, drag it here.',
                metaFields: [{ id: 'name', name: 'Name', placeholder: 'Name' }],
                inline: true,
                width: '100%',
                doneButtonHandler: false
            }" />
        <v-alert v-if="error" text type="error" class="mt-4">{{ error }}</v-alert>
    </div>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import { mapState } from 'vuex'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import axios from 'axios'
import { DateTime } from 'luxon'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default {
    name: 'UppyUpload',
    components: {
        Dashboard
    },
    props: {
        endpoint: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        reset: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uppy: null,
            error: '',
            httpStatusCodes: []
        }
    },
    created() {
        this.initUppy()
    },
    beforeDestroy() {
        this.uppy.close({ reason: 'unmount' })
    },
    methods: {
        async initUppy() {
            const accessToken = await this.$auth.getTokenSilently()
            const xhrEndpoint = this.$axios.defaults.baseURL + this.endpoint
            this.uppy = new Uppy().use(XHRUpload, {
                endpoint: xhrEndpoint,
                timeout: 600 * 1000,
                limit: 5,
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
                fieldName: 'file'
            })
            this.uppy.on('upload-error', (file, error, response) => {
                if (response.body.code === 'max_quota_exceeded') {
                    this.error = 'File upload failed because you exceeded your file quota!'
                } else {
                    this.error = 'File upload failed due to network issues.'
                }

                // group errors by http status codes to not spam slack
                if (this.httpStatusCodes.includes(response.status)) return
                this.httpStatusCodes.push(response.status)

                const slackMessage = {
                    blocks: [
                        {
                            type: 'header',
                            text: {
                                type: 'plain_text',
                                text: ':fire: File upload error',
                                emoji: true
                            }
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text: '*Request:*\n```' + xhrEndpoint + '```'
                            }
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text: '*Error:*\n```' + error.stack + '```'
                            }
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text: '*Response:*\n```' + JSON.stringify(response) + '```'
                            }
                        },
                        {
                            type: 'section',
                            fields: [
                                {
                                    type: 'mrkdwn',
                                    text: '*Time:*\n' + DateTime.now().toFormat(this.$appConfig?.VUE_APP_LONGDATETIMEFORMAT)
                                },
                                {
                                    type: 'mrkdwn',
                                    text: '*Access token:*\n' + (accessToken ? ':white_check_mark: available' : ':negative_squared_cross_mark: nan')
                                }
                            ]
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text: '*User:*\n```' + JSON.stringify(this.userInfo) + '```'
                            }
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text: '*File:*\n```' + JSON.stringify(file) + '```'
                            }
                        }
                    ]
                }
                this.postToSlack(slackMessage)
            })
            this.uppy.on('complete', result => {
                this.httpStatusCodes = []
                this.$emit('uploading', false)
                if (result.successful.length) {
                    const newFiles = result.successful.map(file => file.response.body)
                    this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                        newData: newFiles,
                        updateMode: 'add'
                    })
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                    this.$store.dispatch('snapshotStore/setShowUploadSnackbar', true)
                    setTimeout(() => {
                        this.$emit('uploadComplete', true)
                    }, 2000)
                }
            })
            this.uppy.on('upload', data => {
                this.$emit('uploading', true)
            })
        },
        async postToSlack(message) {
            try {
                const simpleAxios = axios.create()
                await simpleAxios.post('https://hooks.slack.com/services/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', message, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        reset(need) {
            if (!need) return
            this.uppy.cancelAll()
            this.error = ''
        },
        endpoint() {
            this.initUppy()
        }
    }
}
</script>
